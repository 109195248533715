<template>
    <div class="mainPage">
        <div class="mainPageContent">
            <div class="contentPanel">
                <div class="headerLogo">
                    <img src="../assets/logo_b.png" alt="Osmose" />
                </div>
            </div>
            <div class="btnfixed">
                <router-link to="/startnewpole">
                    <button class="btnwht txtUppercase mb-2">
                        Start New Pole
                    </button>
                </router-link><br />
                <button class="btnwht txtUppercase" @click="openSavedPole()">
                    Open Saved Pole
                </button>
            </div>
            <shared-footer></shared-footer>
        </div>
    </div>
</template>

<script>
    import SharedFooter from "../components/SharedFooter.vue";
    import Eruda from 'eruda';

    export default {
        name: "Dashboard",
        
        props: {
            msg: String,
        },

        components: {
            "shared-footer": SharedFooter,
        },

        methods: {
            openSavedPole() {
                this.$router.push("/poleInfo");
            },
        },
        mounted() {
            //debugger;
            // use this for visibility
            if (localStorage.getItem("ErudaEnabled") === "Yes")
                Eruda.init();
        },
    };
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="scss">
    .mainPage {
        height: 100vh;
    }

    .headerLogo {
        img {
            width: 230px;
        }
    }

    .mainPageContent {
        margin: auto;
    }

    .listInline {
        list-style: none;
        padding-left: 0;
        font-size: 14px;
        margin: 1rem 0px;

        .listItem {
            display: inline-block;
            margin-right: 1rem;

            a {
                text-decoration: none;
            }
        }

        .listItem:last-child {
            margin-right: 0rem;
        }
    }

    .bottomFixed {
        position: fixed;
        bottom: 0px;
        width: 100%;
        text-align: center;

        .listInline a {
            color: #003468;
        }
    }

    .contentPanel {
        position: absolute;
        top: 8%;
        left: 50%;
        -ms-transform: translate(-50%, -8%);
        transform: translate(-50%, -8%);
        width: 350px;
        text-align: center;
    }

    .title1 {
        margin: 5% 0px;
        text-align: center;
        color: #003468;
        font-weight: 600;
        font-size: 20px;
    }

    .btnwht {
        background: #003468;
        border-radius: 8px;
        padding: 20px 16px;
        color: #fff;
        font-size: 16px;
        border: 0px;
        cursor: pointer;
        font-weight: 600;
        width: 100%;
    }

    .btnfixed {
        position: absolute;
        bottom: 50%;
        width: 350px;
        text-align: center;
        transform: translate(-50%, 50%);
        left: 50%;
    }

    //Tabs Only
    @media (min-width: 768px) {
        .btnfixed {
            position: absolute;
            bottom: 50%;
            width: 350px;
            text-align: center;
            transform: translate(-50%, 50%);
            left: 50%;
        }
    }
    //Desktop only
    @media (min-width: 992px) {
    }

    //Small mobile
    @media (max-width: 374.5px) {
        .contentPanel,
        .btnfixed {
            width: 90% !important;
        }
    }
</style>
